import useUrlData from "@components/SearchResults/hooks/useUrlData"
import Seo from "@components/seo"
import { createResultsCanonicalUrl, getH1Text, getSeoDesc } from "@lib/searchUtils"
import "@styles/search-results.scss"
import SearchResultsPage from "@templates/search-results-template"
import React, { useEffect, useState } from "react"

const SearchResults = (props) => {
  return <SearchResultsPage {...props} />
}

export const Head = () => {
  const pageUrlData = useUrlData()

  const h1Text = getH1Text(pageUrlData)
  const seoDesc = getSeoDesc(pageUrlData)
  const [customCanonical, setCustomCanonical] = useState("https://www.providentestate.com/buy/properties-for-sale/")

  useEffect(() => {
    const fetchCanonicalUrl = async () => {
      const canonicalUrl = await createResultsCanonicalUrl({ pageUrlData })
      setCustomCanonical(process.env.GATSBY_SITE_URL+canonicalUrl)
    }

    fetchCanonicalUrl()
  }, [pageUrlData])
  if (!customCanonical) {
    // You can return a loading state or default canonical URL here if needed
    return null
  }
  // let customCanonical = createResultsCanonicalUrl({pageUrlData})
  return <Seo 
  customCanonical={customCanonical}
   title={h1Text} description={seoDesc} />
}

export default SearchResults
